import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function parallax() {
  [...document.querySelectorAll('[data-parallax]')].forEach((el) => {
    let yPos = '20%';

    if (el.dataset.parallax !== '') {
      yPos = el.dataset.parallax;
    }

    gsap.to(el, {
      y: yPos,
      ease: 'none',
      duration: 1,
      scrollTrigger: {
        trigger: el,
        scrub: true,
      },
    });
  });
}

function selfAnimation() {
  [...document.querySelectorAll('.js-animate-self')].forEach((el) => {
    gsap.from(el, {
      duration: 1,
      ease: 'Power3.easeOut',
      y: '30px',
      opacity: 0,
      scrollTrigger: el,
    });
  });
}

export default function GlobalAnimations() {
  parallax();
  selfAnimation();
}
