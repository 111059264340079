import { gsap } from 'gsap';

export default class Menu {
  constructor(selector) {
    this.STATE_OPEN = 'open';
    this.STATE_CLOSED = 'closed';
    this.state = this.STATE_CLOSED;
    this.dom = {
      menu: document.querySelector(selector),
      triggers: [...document.querySelectorAll(`[data-target="${selector}"]`)],
      html: document.documentElement,
    };
    this.dom.listItems = [...this.dom.menu.querySelectorAll('li')];
    this.breakpoint = 767;
    this.activeClass = 'menu-open';
    this.timeline = gsap.timeline();

    this.init();
  }

  init() {
    // Add links
    this.dom.triggers.push([...this.dom.menu.querySelectorAll('a')]);
    // Flatten array
    this.dom.triggers = [].concat(...this.dom.triggers);
    // Add click handlers
    this.dom.triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.onClick.bind(this));
    });
  }

  setState() {
    switch (this.state) {
      case this.STATE_OPEN:
        this.state = this.STATE_CLOSED;
        this.onHide();
        break;
      case this.STATE_CLOSED:
        this.state = this.STATE_OPEN;
        this.onShow();
        break;
      default:
        break;
    }
  }

  setButtonState() {
    const isAriaExpanded = this.state === this.STATE_OPEN;

    this.dom.triggers.forEach((trigger) => {
      trigger.setAttribute('aria-expanded', isAriaExpanded);
    });
  }

  onClick() {
    if (window.innerWidth <= this.breakpoint) {
      this.setState();
      this.setButtonState();
    }
  }

  onShow() {
    this.showMenu();
    this.animateTextIn();
  }

  onHide() {
    this.animateTextOut(this.hideMenu);
  }

  showMenu() {
    this.dom.html.classList.add(this.activeClass);
  }

  hideMenu() {
    this.dom.html.classList.remove(this.activeClass);
  }

  animateTextIn() {
    gsap.set(this.dom.listItems, { clearProps: 'all' });

    this.timeline
      .clear()
      .from(this.dom.listItems, {
        duration: 0.3,
        opacity: 0,
        y: 40,
        ease: 'power1.out',
        stagger: 0.1,
      });
  }

  animateTextOut(cb) {
    const reversedListItems = [...this.dom.listItems].reverse();
    const self = this;
    this.timeline
      .clear()
      .to(reversedListItems, {
        duration: 0.15,
        opacity: 0,
        y: 40,
        ease: 'power1.in',
        stagger: 0.1,
        onComplete: () => {
          cb.call(self);
          gsap.set(this.dom.listItems, { clearProps: 'all' });
        },
      });
  }
}
