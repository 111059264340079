import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { debounce } from 'lodash-es';

gsap.registerPlugin(ScrollTrigger);

class ScrubImages {
  constructor(carousel) {
    this.carousel = carousel;
    this.carouselWrapper = carousel.querySelector('.swiper-wrapper');
    this.slides = [...carousel.querySelectorAll('.swiper-slide')];
    this.margin = 0;
    this.width = 0;
    this.scroller = null;

    window.addEventListener('resize', debounce(this.resize.bind(this), 200));
    this.resize();
  }

  getWidth() {
    this.margin = parseFloat(window.getComputedStyle(this.slides[0]).marginRight);
    this.width = (this.slides[0].offsetWidth + this.margin) * this.slides.length;
    this.width -= window.innerWidth + this.margin;
    return this.width;
  }

  resize() {
    // Reset
    if (this.scroller) {
      this.scroller.kill(true);
      ScrollTrigger.getById('triggerCarousel').kill(true);
      gsap.set(this.carouselWrapper, { clearProps: true });
    }
    this.animate();
  }

  animate() {
    // const footerMeta = document.querySelector('.footer-meta');
    // const marginTop = Math.abs(parseFloat(window.getComputedStyle(footerMeta).marginTop));
    // const height = this.carousel.offsetHeight - marginTop;
    const dir = this.carousel.getAttribute('dir');
    const xPos = dir === 'rtl' ? this.getWidth() : -this.getWidth();

    this.scroller = gsap.to(this.carouselWrapper, {
      x: xPos,
      ease: 'none',
      scrollTrigger: {
        id: 'triggerCarousel',
        trigger: this.carousel,
        start: 'top bottom',
        // end: `+=${footerMeta.offsetHeight + height}`,
        scrub: true,
        invalidateOnRefresh: true,
      },
    });
  }
}

export default function initScrubFooter() {
  const swipers = [...document.querySelectorAll('.swiper-scrub')];

  swipers.forEach((swiper) => {
    if (swiper && swiper.classList.contains('swiper-container-initialized')) {
      // Swiper is initialized
      // eslint-disable-next-line no-new
      new ScrubImages(swiper);
    } else if (swiper) {
      // Swiper is not yet initialized
      // Watch for swiper initialization
      const observerOptions = {
        attributes: true,
      };

      const observer = new MutationObserver((() => {
        if (swiper.classList.contains('swiper-container-initialized')) {
          // eslint-disable-next-line no-new
          new ScrubImages(swiper);
          observer.disconnect();
        }
      }));
      observer.observe(swiper, observerOptions);
    }
  });
}
