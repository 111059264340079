export default class FormValidation {
  constructor(form) {
    this.dom = {
      form,
      formElements: form.querySelectorAll('input, textarea'),
      alertSuccess: form.querySelector('.alert-success'),
      alertDanger: form.querySelector('.alert-danger'),
      submit: form.querySelector('button[type=submit]'),
    };
    this.classes = {
      hidden: 'd-none',
      invalid: 'is-invalid',
    };

    this.init();
  }

  /**
   * Submit form
   * @param e { this }
   */
  submitForm(e) {
    e.preventDefault();

    const formData = new FormData(this.dom.form);
    const method = this.dom.form.getAttribute('method');
    const action = this.dom.form.getAttribute('action');
    const headers = new Headers({
      'X-Requested-With': 'XMLHttpRequest',
      HTTP_X_REQUESTED_WITH: 'XMLHttpRequest',
    });

    this.removeMessages();
    this.dom.submit.disabled = true;

    fetch(
      action || window.location.href,
      {
        method,
        cache: 'no-cache',
        headers,
        body: formData,
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.dom.form.reset();

          // Redirect if an url is set
          if (data.returnUrl) {
            window.location.href = data.returnUrl;
          }
          this.renderFormSuccess();
        } else if (data.errors || data.formErrors) {
          this.renderErrors(data.errors);
        }
        this.replaceInputs(data);
      })
      .catch(() => {
        this.renderFormError();
      })
      .finally(() => {
        this.unlockSubmit();
      });
  }

  /**
   * Hide errors messages and alerts
   */
  removeMessages() {
    const inputs = [...this.dom.form.getElementsByClassName(this.classes.invalid)];
    inputs.forEach((input) => {
      input.classList.remove(this.classes.invalid);
    });

    this.dom.alertSuccess.classList.add(this.classes.hidden);
    this.dom.alertDanger.classList.add(this.classes.hidden);
  }

  /**
   * Show success message
   */
  renderFormSuccess() {
    this.dom.alertSuccess.classList.remove(this.classes.hidden);
  }

  /**
   * Show error message / no errors messages but form request failed
   */
  renderFormError() {
    this.dom.alertDanger.classList.remove(this.classes.hidden);
  }

  /**
   * Show errors
   * @param errors { Object }
   */
  renderErrors(errors) {
    Object.keys(errors)
      .forEach((key) => {
        this.dom.form.querySelector(`[name="${key}"]`)
          .classList
          .add(this.classes.invalid);
      });
  }

  replaceInputs(data) {
    if (data.duplicateCheck) {
      const { prefix } = data.duplicateCheck;
      const { value } = data.duplicateCheck;
      const input = this.dom.form.querySelector(`input[name^="${prefix}"]`);
      if (input) {
        input.setAttribute('name', value);
        input.setAttribute('value', value);
      }
    }
  }

  /**
   * Remove the 'disabled' state of the submit button
   */
  unlockSubmit() {
    this.dom.submit.disabled = false;
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.reset(); // eslint-disable-line no-undef
    }
  }

  /**
   * On change remove invalid class
   * @param e { Event }
   */
  formElementChange(e) {
    e.target.classList.remove(this.classes.invalid);
  }

  init() {
    this.dom.form.addEventListener('submit', this.submitForm.bind(this));
    [...this.dom.formElements].forEach((el) => {
      el.addEventListener('input', this.formElementChange.bind(this));
    });
  }
}
