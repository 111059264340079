// Import our CSS
import Vue from 'vue';
import Menu from './components/menu/menu';
import FormValidation from './components/validation/form';
import GlobalAnimations from './components/animations/global';
import ScrubImages from './components/animations/scrub_images';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import '../scss/main.scss';
import './components';

function requireAll(r) {
  r.keys()
    .forEach(r);
}

requireAll(require.context('../../cms/web/img/icons', true, /\.svg$/));

// App main
/* eslint-disable no-new */
new Vue({
  el: '#page-container',
  delimiters: ['${', '}'],
  components: {},
  data: {},
  methods: {
    elementInViewport() {
      const elements = Array.from(document.querySelectorAll('video, .btn-play'));
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const element = entry.target;
          const isMedia = element.tagName === 'VIDEO' || element.tagName === 'AUDIO';

          if (entry.isIntersecting) {
            element.style.animationPlayState = 'running';

            if (isMedia) {
              element.play();
            }
          } else {
            element.style.animationPlayState = 'paused';

            if (isMedia) {
              element.pause();
            }
          }
        });
      });

      elements.forEach((element) => {
        observer.observe(element);
      });
    },
  },
  mounted() {
    this.elementInViewport();

    // Create menu
    if (document.getElementById('menu')) {
      new Menu('#menu');
    }

    // Validate Forms
    [...document.querySelectorAll('.js-validate-form')].forEach((form) => {
      new FormValidation(form);
    });

    GlobalAnimations();
    ScrubImages();
  },
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
